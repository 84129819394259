<template>
  <div class="robotArmPose-component">

    <div class="robotArmPose">
      <div class="odd-arm">
        <div class="arm-type">奇臂</div>
        <div class="item" v-for="(odd, index) in arm.odd" :key="index">
          <div :class="odd.id == 'crossshrink' || odd.id == 'crossextend' ? 'style-margin-top' : ''">
            <Icon :name="odd.status ? 'warn' : 'normal'" />
            {{ odd.label }}
          </div>
        </div>
      </div>

      <div class="even-arm">
        <div class="arm-type">偶臂</div>
        <div class="item" v-for="(even, index) in arm.even" :key="index">
          <div>
            <Icon :name="even.status ? 'warn' : 'normal'" />
            {{ even.label }}
          </div>
        </div>
        <div class="item" style="width:100%;">
          <div style="margin-top:.6vh;">
            <Icon :name="charge ? 'warn' : 'normal'" />
            充电对接
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";
import { mapState } from "vuex";
export default {
  name: "robotArmPose",

  components: {
    Icon
  },

  computed: {
    ...mapState(["socketContent"])
  },

  watch: {
    socketContent: {
      handler(newSensorValue) {
        for (const key in newSensorValue.data.sensorvalue) {
          if (Object.hasOwnProperty.call(newSensorValue.data.sensorvalue, key)) {
            const status = newSensorValue.data.sensorvalue[key];

            // 更改奇臂状态
            this.arm.odd.forEach(item => {
              if (item.id == key) {
                item.status = status == 0;
              }
            });

            // 更改偶臂状态
            this.arm.even.forEach(item => {
              if (item.id == key) {
                item.status = status == 0;
              }
            });
            
            // 更改充电状态
            if (key == "charge") {
              this.charge = status == 0;
            }
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      arm: {
        odd: [
          { id: "oddoutertouch", label: "外碰", status: false },
          { id: "oddinnertouch", label: "内碰", status: false },
          { id: "oddpressup", label: "压紧", status: false },
          { id: "oddpressdown", label: "松开", status: false },
          { id: "oddlock", label: "锁臂", status: false },
          { id: "oddunlock", label: "松臂", status: false },
          { id: "crossshrink", label: "收臂", status: false },
          { id: "crossextend", label: "展臂", status: false }
        ],
        even: [
          { id: "evenoutertouch", label: "外碰", status: false },
          { id: "eveninnertouch", label: "内碰", status: false },
          { id: "evenpressup", label: "压紧", status: false },
          { id: "evenpressdown", label: "松开", status: false },
          { id: "evenlock", label: "锁臂", status: false },
          { id: "evenunlock", label: "松臂", status: false }
        ]
      },
      charge: 0
    };
  }
};
</script>
<style lang="scss" scoped>
.style-margin-top {
  margin-top: 0.6vh;
}
.robotArmPose-component {
  margin: 0.6vh 0.3vw;
  .robotArmPose {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .odd-arm {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .arm-type {
        width: 100%;
        font-size: 1.3vh;
      }
      .item {
        width: 50%;
        font-size: 1.3vh;
        margin-top: 0.2vh;
      }
    }
    .even-arm {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .arm-type {
        width: 100%;
        font-size: 1.3vh;
      }
      .item {
        width: 50%;
        font-size: 1.3vh;
        margin-top: 0.2vh;
      }
    }
  }
}
</style>
