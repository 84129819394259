<template>
  <div class="professionalControl-page">
    <div class="top-router">
      <RouterMenu />
    </div>
    <div class="main">

      <div class="left">
        <!-- 机器人列表 -->
        <div class="son-header" style="display: flex; justify-content: space-between">
          <div>
            <Icon name="lineInfo" class="icon-class" />
            机器列表
          </div>
          <i class="el-icon-refresh" style="cursor: pointer" title="刷新机器列表" @click="refreshRobotList"></i>
        </div>
        <div class="robot-list">
          <ul>
            <li v-for="(robot, index) in getAllOnlineRobotList" :key="index" @click.stop="handleChooseRobot(robot)"
              :class="{ 
                'not-online': !robot.isOnline, 
                'li-actived': robot.robotMac == currentLoginedRobot.robotMac,
                'choose-line': chooseRobot.id == robot.id
              }" v-loading.fullscreen.lock="isLoginLoading" element-loading-text="正在登录，请稍候~" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
              <el-tooltip class="item" effect="light" :content="robot.robotName" placement="right" :open-delay="1000">
                <span class="robotName">
                  <Icon name="greenMachine" />
                  {{ robot.robotName }}
                </span>
              </el-tooltip>
              <span  v-if="!robot.isOnline" style="text-align:right with:100px "> 离线</span>
              <el-button class="kr-normal-button" v-else-if="robot.robotMac == currentLoginedRobot.robotMac" style="text-align:right" @click="logoutRobotBtn(robot)">退出</el-button>
              <el-button class="kr-normal-button" @click="loginRobotBtn(robot)" :disabled="!robot.isOnline" v-else-if="$hasElement('robot-btn-login')" >登录</el-button>
            </li>
          </ul>
        </div>

        <!-- 机器人信息 -->
        <div class="son-header log-admin"> 
          <el-row :gutter="20" style="width: 100%">
            <el-col :span="8" class="kr-normal-button tab-button" :class="logOrAdmin == 0 ? 'green-button-actived' : ''">
                <div  @click.stop="logOrAdmin = 0">
                  <Icon name="baseInfo" />
                  操作日志
                </div>
            </el-col>
            <el-col :span="8" class="kr-normal-button tab-button" :class="logOrAdmin == 1 ? 'green-button-actived' : ''">
                <div  @click.stop="logOrAdmin = 1">
                  <Icon name="defect" />
                  管理模式
                </div>
            </el-col>
            <el-col :span="8" class="kr-normal-button tab-button" :class="logOrAdmin == 2 ? 'green-button-actived' : ''"  v-if="chooseRobot.robotType=='ROBOT_TYPE_25'">
                <div  @click.stop="handleClickRstBtn">
                  <Icon name="reset" />
                  重启模块
                </div>
            </el-col>
          </el-row>
        </div>
        <div class="log-admin-list">
          <div class="log-div" v-show="logOrAdmin == 0">
            <LogPanel ref="LogPanel" />
          </div>
          <div class="admin" v-show="logOrAdmin == 1">
            <div class="admin-item" v-for="(adminBtn, index) in adminList" :key="index">
              <div class="admin-title" :title="adminBtn.title">{{ adminBtn.title }}</div>
              <div class="btn-group" v-if="adminBtn.type === 'notNeedAuth'">
                 <Icon v-if="adminBtn.hasOwnProperty('status')" :name="adminBtn.status ? 'warn' : 'normal'" />
                <el-button class="normal-small-button" @click="adminSwitchWaveSleep(adminBtn.cmd1)" v-if="$hasElement(adminBtn.elementLeft)">{{ adminBtn.label1 }}</el-button>
                <el-button class="normal-small-button" @click="adminSwitchWaveSleep(adminBtn.cmd2)" v-if="$hasElement(adminBtn.elementRight)">{{ adminBtn.label2 }}</el-button>                
              </div>
              <div class="btn-group" v-else-if="adminBtn.type === 'openClose'">
                <Icon v-if="adminBtn.hasOwnProperty('status')" :name="adminBtn.status ? 'warn' : 'normal'" />
                <el-button :disabled="getIsDisabled" class="normal-small-button" @click="adminSwitchOpenClose(adminBtn, true)" v-if="$hasElement(adminBtn.elementLeft)">开启</el-button>
                <el-button :disabled="getIsDisabled" class="normal-small-button" @click="adminSwitchOpenClose(adminBtn, false)" v-if="$hasElement(adminBtn.elementRight)"> 关闭</el-button>  
              </div>
              <div class="btn-group" v-else>
                <div style="width: 1em; height: 1em; margin-right: 0.5208vw"></div>
                <el-button :disabled="getIsDisabled" class="normal-small-button" @click="adminSwitchBtn(adminBtn.cmd1)" v-if="$hasElement(adminBtn.elementLeft)">{{ adminBtn.label1 }} </el-button>
                <el-button :disabled="getIsDisabled" class="normal-small-button" @click="adminSwitchBtn(adminBtn.cmd2)" v-if="$hasElement(adminBtn.elementRight)">{{ adminBtn.label2 }}</el-button>   
              </div>
            </div>
          </div>
          <div class="reset" v-show="logOrAdmin == 2" v-loading="resetLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <slight-robot-restart ref="slightRobotRestartRef" :isShow="isShowRestartModule" :currentRobotRst="currentRobotRst"/>
          </div>
        </div>
      </div>


      <!-- 视频播放 -->
      <div class="center">
        <centerVideo2 :cameraList="cameraList" v-if="currentLoginedRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED' || chooseRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED'" />
        <centerVideo :modeType="modeIndex" :videoStatus="videoStatus"  :videoKey="videoKey" :isStop="isStop" v-else />
      </div>


      <div class="right">
        <!-- 机械臂状态 -->
        <div class="arm-status">
          <div class="son-header baseInfo-ctrl">机械臂状态</div>
          <div class="robot-arm-pose">
            <robotArmPose />
          </div>
        </div>

        <!-- 模式 -->
        <div class="mode">
          <div class="son-header baseInfo-ctrl">
            <div class="tab-item" :class="{'active':modeIndex == 0}" @click="changeModeTab(0);">手动模式</div>
            <div class="tab-item" :class="{'active':modeIndex == 1}" @click="changeModeTab(1);" v-if="((currentLoginedRobot.robotType == 'ROBOT_TYPE_25' || currentLoginedRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED')&&currentLoginedRobot.robotMac)||!currentLoginedRobot.robotMac">视觉模式</div>
          </div>

          <template v-if="modeIndex == 0">
            <div class="professional-ctrl">

              <!-- 机械臂控制 -->
              <div class="arm-control">
                <robotArmControl />
              </div>
              <div class="robot-control">
                <div class="robot-button">
                  <el-button :disabled="getIsDisabled" class="robot-backward" title="后退" @click="robotMove('backward')" v-if="$hasElement('robot-btn-walk')"></el-button>
                  <el-button :disabled="getIsDisabled" class="robot-stop" title="停止" @click="robotStop" v-if="$hasElement('robot-btn-brake')"></el-button>
                  <el-button :disabled="getIsDisabled" class="robot-forward" title="前进" @click="robotMove('forward')" v-if="$hasElement('robot-btn-walk')"></el-button>
                </div>
              </div>

              <!-- 云台控制 -->
              <div class="sub-title">云台控制</div>
              <div class="cloud-control">
                <cloudControl @returnIsUseCloudControl="getIsUseControl" />
              </div>

              <!-- 云台坐标 -->
              <div class="sub-title">云台坐标</div>
              <div class="cloud-posi" style="display: flex; margin-top: 0.5vh">
                <el-input class="normal-input abscissa" type="number" :min="0" :disabled="isUseControl" :max="360000" v-model.number.trim="abscissa"></el-input>
                <el-input class="normal-input ordinate" type="number" :min="0" :disabled="isUseControl" :max="360000" v-model.number.trim="ordinate"></el-input>
              </div>
              <div style="display: flex; justify-content: space-around">
                <el-button :disabled="getIsDisabled || isUseControl" type="text" @click="getCloudPosition()" v-if="$hasElement('ptz-btn-getpos')">获取</el-button>
                <el-button :disabled="getIsDisabled || isUseControl" type="text" @click="setCloudPosition()" v-if="$hasElement('ptz-btn-setpos')">设置</el-button>
              </div>
            </div>
          </template>

          <!-- 视觉控制 -->
          <template v-else>
            <visualMode @changeVideoStatus="changeVideoStatus"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RouterMenu from "@/components/RouterMenu.vue";
import LogPanel from "../../components/control/LogPanel.vue";
import Icon from "@/components/Icon.vue";
// import LivePlayer from "@/components/LivePlayer.vue";
import cloudControl from "../../components/control/cloudControl.vue"; //云台控制
import robotArmPose from "@/components/robotArmPose.vue"; //机器人手臂状态
import robotArmControl from "@/components/robotArmControl.vue"; //机器人手臂控制
import centerVideo from "@/components/centerVideo.vue";
import visualMode from "@/components/control/visualMode.vue";
import { loginRobot,
        logoutRobot,
        robotSwitch, 
        robotBrake, 
        robotWalk, 
        getAllRobot, 
        getOnlineRobot, 
        ptzGetpos, 
        ptzSetpos, 
        robotSleepWaveUp,
        rebootRoute,
        reboot,
        robotQuery,
        openVideoServer,
        switchAdminApi
} from "@/http/professionalControlApi.js";
import { loginH5sStream, keepAliveSession, getCameraListByRobotIdApi } from '../../http/equipmentApi'
import { mapState, mapGetters, mapMutations } from "vuex";
import * as robotWebsocket from "@/utils/websocket/robotWebsocket.js";
import SlightRobotRestart from '@/components/restart/SlightRobotRestart.vue';
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import centerVideo2 from "../../components/centerVideo2.vue";
export default {
  name: "professionalControl",

  components: {
    LogPanel,
    RouterMenu,
    Icon,
    cloudControl,
    robotArmPose,
    robotArmControl,
    centerVideo,
    visualMode,
    SlightRobotRestart,
    centerVideo2
  },

  data() {
    return {
      /*
       * @Date 2022/06/16 09:41:21
       * 机器人列表信息
       */
      chooseRobot:'',   // 当前选择的机器人
      isLoginLoading: false,// 是否正在登录机器人 
      timeOutTimer: null,   // 登录机器人超时定时器

      /*
       * @Date 2022/06/16 09:41:29
       * 机器人信息
       */
      logOrAdmin: 0,                    // 显示日志还是管理模式
      resetLoading: false,              // 重启模块的loading显隐
      slightRobotRstStatus: "offline",   // 25KG的机器在线状态
      slightRobotRstRelayStatus: false, // 25KG的重启模块继电器状态：继电器开-机器关机，继电器关-机器关机
      getSlightRobotRstStatusTimeout: 0,// 查询25kg机器人重启模块超时标识
      adminList: [{
          title: "机器人",
          label1: "重启程序",
          cmd1: "reboot",
          label2: "查询版本",
          cmd2: "version",
          elementLeft: 'robot-btn-reboot-software',
          elementRight: 'robot-btn-query'
        },
        {
          title: "充电",
          label1: "开启",
          cmd1: "chargeopen",
          label2: "关闭",
          cmd2: "chargeclose",
          elementLeft: 'robot-btn-charge',
          elementRight: 'robot-btn-charge'
        },
        {
          title: "机构系统",
          label1: "开启",
          cmd1: "mechpowon",
          label2: "关闭",
          cmd2: "mechpowoff",
          elementLeft: 'robot-btn-mech',
          elementRight: 'robot-btn-mech'
        },
        {
          title: "板载",
          label1: "开启",
          cmd1: "carrierpowon",
          label2: "关闭",
          cmd2: "carrierpowoff",
          elementLeft: 'robot-btn-carrier',
          elementRight: 'robot-btn-carrier',
        },
        {
          title: "摄像头",
          label1: "开启",
          cmd1: "camerapowon",
          label2: "关闭",
          cmd2: "camerapowoff",
          elementLeft: 'robot-btn-camera',
          elementRight: 'robot-btn-camera'
        },
        {
          title: "风扇",
          label1: "开启",
          cmd1: "fanon",
          label2: "关闭",
          cmd2: "fanoff",
          elementLeft: 'robot-btn-fan',
          elementRight: 'robot-btn-fan'
        }
      ],

      /*
       * @Date 2022/06/16 09:50:42
       * 监控视频信息
       */
      modeIndex: 0,     // 选中模式 0手动和 1视觉 当机器类型是ROBOt_TYPE_25 是视觉模式
      videoStatus: '',  // '' 恢复手动; 0 停止播放; 1 奇臂; 2 偶臂; 3 视觉模式下停止播放
      videoKey:0,       // 标识是哪一个视频
      isStop:0,
      cameraList: [],   // 分布式机器人摄像头列表


      /*
       * @Date 2022/06/17 16:16:05
       * 云台控制信息
       */
      isUseControl: false,// 是否能使用云台控制


      /*
       * @Date 2022/06/16 09:52:28
       * 云台坐标信息
       */
      abscissa: "",     // 横坐标
      ordinate: "",     // 纵坐标


      relaySwitch:false,
      modePlayStatus:'',
      chooseRobot:'',
      isCheckStatus:false,

      // 重启模块相关成员
      isShowRestartModule: false,
      currentRobotRst: {  robotName: "", restartModuleName: "", restartModuleMac: ""},
      
    };
  },

  computed: {
    ...mapState(["userMessage", "ptzMsg", "currentLoginedRobot", "session", "timeout", "keepalive", "socketContent", "allRobotList"]),
    ...mapGetters(["getAllOnlineRobotList", "getIsDisabled"]),
    /**
    * @Author KR0132
    * @Date 2022/06/01 13:14:52
    * @Description 25kg重启模块开关状态
    */
    slightRobotRstStatusTag: function() {

      if(!this.slightRobotRstStatus) {
        return "error";
      }
      if (this.slightRobotRstStatus=="online") {
        return "success";
      }else if(this.slightRobotRstStatus=="offline") {
        return "warning";
      }else {
        return "error";
      }
    },

    /**
    * @Author KR0132
    * @Date 2022/06/01 13:14:52
    * @Description 25KG重启模块状态描述
    */
    slightRobotRstStatusText: function() {
      if(!this.slightRobotRstStatus) {
        return "查询失败";
      }
      if (this.slightRobotRstStatus=="online") {
        return "在线";
      }else if(this.slightRobotRstStatus=="offline") {
        return "离线";
      }else {
        return "查询失败";
      }
    },

  /**
    * @Author KR0132
    * @Date 2022/06/01 13:14:52
    * @Description 25KG重启模块允许设置状态
    */
    slightRobotRstEnable: function() {
      return !(this.slightRobotRstStatus=="online" && !this.resetLoading);
    }
  },

  methods: {
    ...mapMutations(["updateSession", "updateTimeout", "updateCurrentLoginedRobot", "initSensor", "updatePCUOfSensorState", "updateRobotAddress"]),
    /**
     * @Author KR0288
     * @Date 2022/06/06 08:53:50
     * @Description 获取该用户下的所有机器人,并保存在vuex中
     */
    getAllRobotList() {
      let params = {
        pageNumber: 1,
        pageSize: 1000,
      };
      getAllRobot(params).then((res) => {
        this.$store.commit("saveAllRobot", res.rows);
      }).catch(err => {
        this.$message.error("获取机器人列表失败");
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:53:50
     * @Description 选中机器人
     */
    handleChooseRobot(robot) {
      this.chooseRobot = robot;
      if(robot.robotType=="ROBOT_TYPE_25" ) {
        this.logOrAdmin = 2;
      }
      this.$nextTick(() => {
        if (!this.currentLoginedRobot.robotControlToken) {
        let robotResult = this.allRobotList.find(robotItem => robotItem.robotId === robot.robotId)
        let adminResult = this.adminList.find(adminItem => adminItem.property === "powerState");
        adminResult.status = robotResult.status !== "SLEEP";
        this.updatePCUOfSensorState({
          auxState: false,
          btdState: false,
          evenState: false,
          evenXiLinxState: false,
          ksmState: false,
          laserState: false,
          nxState: false,
          oddState: false,
          oddXiLinxState: false,
          tofState: false,
          ytState: false
        });
      }
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:53:58
     * @Description 获取该用户下所有在线的机器人，并保存在vuex中
     */
    getOnlineRobotList() {
      getOnlineRobot().then((res) => {
        if (res.data) {
          let onlineRobot = Object.values(res.data);
          this.$store.commit("saveOnlineRobot", onlineRobot);
        }
      }).catch(err => {
        this.$message.error("获取在线机器人列表失败");
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:52:41
     * @Description 刷新机器人列表
     */
    async refreshRobotList() {
      try {
        await this.getAllRobotList()
        await this.getOnlineRobotList();
        this.$message.success("刷新机器人列表成功")
      }catch(err) {
        this.$message.error("刷新失败")
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:55:42
     * @Description 登录机器人
     */
    loginRobotBtn(robot) {
      this.isLoginLoading = true;

      // 1、点击登录机器人按钮的时候，先切换订阅主题
      robotWebsocket.changeSubscribeRobot(robot.robotMac);
        
      // 2、超过20秒就按超时处理，刷新页面
      this.timeOutTimer = setTimeout(() => {
        this.isLoginLoading = false;
        this.$message.error("登录超时");
        setTimeout(() => {
          location.reload();
        }, 1000)
      }, 10000)

      // 3、构造参数并登录机器人
      let params = { 
        userName: this.userMessage.userName, 
        isForce: false 
      }

      loginRobot(params, robot.robotMac).then((res) => {
        if (res.code == "000000") {
          let currentCtrlRobot = { 
            robotMac: robot.robotMac, 
            robotName: robot.robotName, 
            robotType: robot.robotType 
          };
          if(!((currentCtrlRobot.robotType == 'ROBOT_TYPE_25' || currentCtrlRobot.robotType == 'ROBOT_TYPE_DISTRIBUTED') && currentCtrlRobot.robotMac) || !currentCtrlRobot.robotMac) {
            this.modeIndex = 0;
          }

          // 1、清空操作日志
          this.$refs.LogPanel.clearLog();

          // 2、清空机械臂状态
          this.initSensor();


          this.$store.commit("updateCurrCtrlRobot", currentCtrlRobot);

        } else if (res.serviceStatusCode == "1000405") {
          this.isLoginLoading = false;
          this.$confirm(`${res.data.userName}已经登录该机器人，是否强制登录？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.isLoginLoading = true;
            robotWebsocket.changeSubscribeRobot(robot.robotMac);
            let params = { 
              userName: this.userMessage.userName, 
              isForce: true 
            };
            loginRobot(params, robot.robotMac).then((res) => {
              if (res.code == "000000") {
                let currentCtrlRobot = { 
                  robotMac: robot.robotMac, 
                  robotName: robot.robotName, 
                  robotType: robot.robotType
                };
                this.$store.commit("updateCurrCtrlRobot", currentCtrlRobot);
              }
              this.timeOutTimer = setTimeout(() => {
                this.isLoginLoading = false;
                this.$message.error("登录超时");
                setTimeout(() => {
                  location.reload();
                }, 1000)
              }, 10000)
            }).catch((err) => {
              /*
              * @Date 2022/03/31 11:32:41
              * 取消登录的时候，将订阅主题切换到原来的主题
              */
              setTimeout(() => {
                robotWebsocket.changeSubscribeRobot(this.$store.state.currentCtrlRobot.robotMac);
              }, 500);
              this.$message("已取消登录");
              this.isLoginLoading = false;
            });
          });
        }
      })
    },

    /**
     * @description: 登出机器人
     * @date 2023-09-05 10:22
     */
    logoutRobotBtn(robot) {
      logoutRobot({
        robotMac: robot.robotMac
      }, this.currentLoginedRobot.robotControlToken).then(res => {
        if (res.code === "000000") {
          // 1、清除当前登录机器人信息
          this.updateCurrentLoginedRobot({        
            robotName: "",
            robotType: "ROBOT_TYPE_25", // 1:50kg 2:25kg
            robotMac: "",               // 机器人mac地址
            robotControlToken: "",      // 登录后获取到的操作机器人token
            count: 100,                 // 位置模式下的范围是100~6900
            velocity: 300,              // 机械臂运动范围是300~18000,机器人前进后退范围300~8000
            model: 1,                   // 模式，1运动模式，2位置模式
            electron: "",               // 当前机器人电量
            h5sToken: {                 // 播放视频的token
              live1: "", 
              live2: "", 
              live3: "", 
              live4: "",
              live5: "",
              live6: ""
            } 
          })
          
          // 2、清空日志
          this.$refs.LogPanel.clearLog();

          // 3、清空视频, 分布式机器人已在centerVideo2关闭了视频
          this.videoStatus = 0;

          // 4、清空机械臂状态
          this.initSensor();

          // 5、清除操作日志信息
          this.updateRobotAddress({
            latitude: "",
            longitude: ""
          })
          this.$message.success("退出机器成功");
        } else {
          this.$message.error(res.mesg)
        }
      }).catch(err => {
        console.log(err);
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/20 09:42:19
     * @Description 登录h5sPlayer
     */
    loginH5s() {
      loginH5sStream({
        user: "admin",
        password: "ab6f67cdc3b8a228e6ce227b39e25217"
      }).then(res => {
        if(res.bStatus) {
          this.updateSession(res.strSession);
          this.updateTimeout(res.nTimeout);
          setInterval(() => {
            this.keepAliveRobotSession();
          }, parseInt((this.timeout * 1000) - 1000));
        }
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/21 09:14:02
     * @Description 保活Session
     */
    keepAliveRobotSession() {
      keepAliveSession({
        session: this.session
      }).then(res => {
        console.log(res);
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:51:34
     * @Description 切换模式
     */
    changeModeTab(index) {
      if(index == 0) {
        let modePlayStatus = this.modePlayStatus;
        console.log(this.modePlayStatus)
        if(modePlayStatus == 1) {
          this.$alert('必须结束录制模式才能切换手动模式', '提示', {
            confirmButtonText: '确定',
            customClass:"video-control-alert"
          });
        }
      };
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:58:15
     * @Description 管理模式开关
     */
    adminSwitchBtn(cmd) {
      if (cmd == "rebootRoute") {
        rebootRoute().then((res) => {
          if (res.code=="000000") {
            this.$message({
              message: res.mesg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.mesg,
              type: "error",
            });
          }
        });
      }
      else if (cmd == 'reboot'){
        this.$confirm('确定要重启模块？', '确认信息', {
          distinguishCancelAndClose: true,
          confirmButtonText: '修改',
          cancelButtonText: '放弃修改'
        }).then(() => {
          reboot().then((res) => {
            if (res.code=="000000") {
              this.$message({
                message: res.mesg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.mesg,
                type: "error",
              });
            }
          });          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消重启'
          })
        })
      } 
      else if (cmd == 'version'){
        robotQuery({cmd:'version'}).then((res) => {
          if (res.code !="000000") {
            this.$message({
              message: res.mesg,
              type: "error",
            });
          }
        });
      }
      else if (cmd != "") {
        robotSwitch({ cmd: cmd }).then((res) => {
          if (res.code=="000000") {
            this.$message({
              message: res.mesg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.mesg,
              type: "error",
            });
          }
        });
      }
    },

    /**
     * @description: 分布式机器人休眠、唤醒
     * @date 2023-07-20 10:21
     */
    adminSwitchWaveSleep(cmd) {
      if (this.chooseRobot) {
        robotSleepWaveUp({
          state: cmd,
          robotMac: this.chooseRobot.robotMac
        }).then(res => {
          if (res.code === "000000") {
            this.$message.success(res.mesg)
             this.refreshRobotList();
          } else {
            this.$message.error(res.mesg)
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        this.$message.error("请先选择机器人")
      }
    },

    /**
     * @description: 分布式机器人管理模式开关按钮
     * @date 2023-09-06 10:07
     */
    adminSwitchOpenClose(adminBtn, status) {
      if (status ? adminBtn.btn1_isNeedDialog : adminBtn.btn2_isNeedDialog) {
        this.$confirm(`是否${status ? '开启' : '关闭'}${adminBtn.title}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          switchAdminApi({
            branch: adminBtn.branch,
            status: status
          }, {
            Authorization: sessionStorage.getItem("Authorization"),
            Ctrl_Authorization: this.currentLoginedRobot.robotControlToken
          }).then(res => {
            if (res.code === "000000") {
              this.$message({
                type: 'success',
                message: res.mesg
              });
            } else {
              this.$message({
                type: 'error',
                message: res.mesg
              });
            }
          }).catch(err => {
            console.log(err);
          })
        }).catch(() => {         
        });
      } else {
        switchAdminApi({
            branch: adminBtn.branch,
            status: status
          }, {
            Authorization: sessionStorage.getItem("Authorization"),
            Ctrl_Authorization: this.currentLoginedRobot.robotControlToken
          }).then(res => {
            if (res.code === "000000") {
              this.$message({
                type: 'success',
                message: res.mesg
              });
            } else {
              this.$message({
                type: 'error',
                message: res.mesg
              });
            }
          }).catch(err => {
            console.log(err);
          })
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/16 11:25:05
     * @Description 点击重启模块标题，显示重启模块的内容
     */
    handleClickRstBtn() {
      this.logOrAdmin = 2;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:51:34
     * @Description 切换模式
     */
    changeModeTab(index) {
      if(index == 0) {
        let modePlayStatus = this.modePlayStatus;
        console.log(this.modePlayStatus)
        if(modePlayStatus == 1) {
          this.$alert('必须结束录制模式才能切换手动模式', '提示', {
            confirmButtonText: '确定',
            customClass:"video-control-alert"
          });
          return false;
        }else if(modePlayStatus == 2) {
          this.$alert('必须结束数据库模式才能切换手动模式', '提示', {
            confirmButtonText: '确定',
            customClass:"video-control-alert"
          });
          return false;
        }else if(modePlayStatus == 3) {
          this.$alert('必须结束纯视觉模式才能切换手动模式', '提示', {
            confirmButtonText: '确定',
            customClass:"video-control-alert"
          });
          return false;
        }
      }
      
      this.modeIndex = index;
      //this.videoKey += 1;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:52:31
     * @Description 修改视频播放状态
     */
    changeVideoStatus(data){
      let videoStatus = data.videoStatus;
      let modePlayStatus = data.modePlayStatus;
      this.videoStatus = videoStatus;
      this.modePlayStatus = modePlayStatus;

      if (!(this.currentLoginedRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED')) {
        // orson
        if(videoStatus == 0) {
          this.isStop += 1;
          return
        }
        this.videoKey += 1;

        // 这里关闭服务是因为视觉模式没用到普通流的视频，所以关闭视频流的推送，节约流量，录制模式和数据库模式默认下位机推流
        if(videoStatus == 1) {
          openVideoServer({ cameranum: 1, status: 0 });
          // openVideoServer({ cameranum: 3, status: 0 });
        }else if(videoStatus == 2) {
          openVideoServer({ cameranum: 1, status: 0 });
          // openVideoServer({ cameranum: 3, status: 0 });
        }        
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:58:03
     * @Description 机器人前进后退按钮
     */
    robotMove(cmd) {
      robotWalk({
        cmd: cmd,
        data: {
          count: this.currentLoginedRobot.count,
          model: this.currentLoginedRobot.model,
          velocity: this.currentLoginedRobot.velocity,
        },
      })
        .then((res) => {
          //业务逻辑
          if (res.code == "000000") {
            this.$message.success(res.mesg);
          } else {
            this.$message.error(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:58:10
     * @Description 机器人停止
     */
    robotStop() {
      robotBrake()
        .then((res) => {
          //业务逻辑
          if (res.code == "000000") {
            this.$message.success(res.mesg);
          } else {
            this.$message.error(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/21 11:15:25
     * @Description 获取是否可以使用云台坐标的权利
     */
    getIsUseControl(value) {
      this.isUseControl = value;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:50:56
     * @Description 获取云台位置
     */
    getCloudPosition() {
      ptzGetpos({
        uart: this.ptzMsg.uart,
      }).then((res) => {
        this.$message({
          message: res.mesg,
          type: "success",
        });
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:51:08
     * @Description 设置云台位置
     */
    setCloudPosition() {
      if (this.abscissa === "" || this.ordinate === "") {
        this.$message({
          message: "云台坐标不能为空",
          type: "warning",
        });
        return;
      }

      var reg = /.*\..*/; //判断有没有小数点
      if (reg.test(this.abscissa) || reg.test(this.ordinate)) {
        this.$message({
          message: "云台坐标不能为小数",
          type: "warning",
        });
        return;
      }

      //设置云台位置
      ptzSetpos({
        abscissa: this.abscissa,
        ordinate: this.ordinate,
        uart: this.ptzMsg.uart,
      }).then((res) => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data,
            type: "error",
          });
        }
      });
    },
  },

  watch: {
    ptzMsg: {
      handler(newVal) {
        this.abscissa = newVal.abscissa;
        this.ordinate = newVal.ordinate;
      },
      deep: true,
    },

    chooseRobot(newVal){
      // 1、处理重启模块
      if(newVal.robotType == "ROBOT_TYPE_25") {
        this.isShowRestartModule = true;
        this.currentRobotRst = {
          robotName: newVal.robotName,
          restartModuleName: newVal.restartModuleName,
          restartModuleMac: newVal.restartModuleMac
        }
      }else {
        this.isShowRestartModule = false;
        this.currentRobotRst = {
          robotName: "",
          restartModuleName: "",
          restartModuleMac: ""
        }
      }

      // 2、处理分布式管理模式按钮组
      if(newVal.robotType == "ROBOT_TYPE_DISTRIBUTED") {
        this.adminList = [{
            title: "机器人",
            type: "normal",
            label1: "重启程序",
            cmd1: "reboot",
            label2: "查询版本",
            cmd2: "version",
            elementLeft: 'robot-btn-reboot-software',
            elementRight: 'robot-btn-query'
          },
          {
            title: "奇臂电源",
            type: "openClose",
            branch: "ODD",
            property: "oddState",
            status: false,
            btn1_isNeedDialog: false,
            btn2_isNeedDialog: true,
            elementLeft: 'robot-btn-odd-on',
            elementRight: 'robot-btn-odd-off'
          },
          {
            title: "偶臂电源",
            type: "openClose",
            branch: "EVEN",
            property: "evenState",
            status: false,
            btn1_isNeedDialog: false,
            btn2_isNeedDialog: true,
            elementLeft: 'robot-btn-even-on',
            elementRight: 'robot-btn-even-off'
          },
          {
            title: "点对点图传",
            type: "openClose",
            branch: "TP",
            property: "btdState",
            status: false,
            btn1_isNeedDialog: false,
            btn2_isNeedDialog: true,
            elementLeft: 'robot-btn-tp-on',
            elementRight: 'robot-btn-tp-off'
          },
          {
            title: "NX视觉",
            type: "openClose",
            branch: "NX",
            property: "nxState",
            status: false,
            elementLeft: 'robot-btn-nx-on',
            elementRight: 'robot-btn-nx-off'
          },
          {
            title: "YT云台",
            type: "openClose",
            branch: "PTZ",
            property: "ytState",
            status: false,
            elementLeft: 'robot-btn-ptz-on',
            elementRight: 'robot-btn-ptz-on'
          },
          {
            title: "AUX 24V电源(底部测距, 载波, 激光雷达, BMS通信)",
            type: "openClose",
            branch: "AUX24V",
            property: "auxState",
            label1: "开启",
            label2: "唤醒",
            status: false,
            elementLeft: 'robot-btn-aux24v-on',
            elementRight: 'robot-btn-aux24v-off'
          },
          {
            title: "TOF 测距",
            type: "openClose",
            branch: "TOF",
            property: "tofState",
            label1: "开启",
            label2: "唤醒",
            status: false,
            elementLeft: 'robot-btn-tof-on',
            elementRight: 'robot-btn-tof-off'
          },
          {
            title: "KSM 载波",
            type: "openClose",
            branch: "KSM",
            property: "ksmState",
            label1: "开启",
            label2: "唤醒",
            status: false,
            elementLeft: 'robot-btn-ksm-on',
            elementRight: 'robot-btn-ksm-off'
          },
          {
            title: "Laser 激光雷达",
            type: "openClose",
            branch: "LASER",
            property: "laserState",
            label1: "开启",
            label2: "唤醒",
            status: false,
            elementLeft: 'robot-btn-laser-on',
            elementRight: 'robot-btn-laser-off'
          },
          {
            title: "奇臂视觉采集电源",
            type: "openClose",
            branch: "ODDVIS",
            property: "oddXiLinxState",
            label1: "开启",
            label2: "唤醒",
            status: false,
            elementLeft: 'robot-btn-oddvis-on',
            elementRight: 'robot-btn-evenvis-off'
          },
          {
            title: "偶臂视觉采集电源",
            type: "openClose",
            branch: "EVENVIS",
            property: "evenXiLinxState",
            label1: "开启",
            label2: "唤醒",
            status: false,
            elementLeft: 'robot-btn-evenvis-on',
            elementRight: 'robot-btn-laser-off'
          },
          {
            title: "重启",
            type: "notNeedAuth",
            label2: "睡眠",
            cmd2: "sleep",
            label1: "唤醒",
            cmd1: "wakeup",
            status: false,
            property: "powerState",
            btn1_isNeedDialog: false,
            btn2_isNeedDialog: true,
            elementLeft: 'robot-btn-wakeup',
            elementRight: 'robot-btn-sleep'
          }
        ];
      }
    },

    /*
     * @Date 2022/06/21 10:30:02
     * 监听websocket是否将机器人控制token返回来后在进行操作
     */
    currentLoginedRobot: {
      handler(newVal, oldVal) {
        console.log(newVal);
        setTimeout(() => {
          this.isLoginLoading = false;
        }, 1000)
        clearTimeout(this.timeOutTimer);
        this.timeOutTimer = null;

        // 切换管理模式按钮组
        if (newVal) {
          if (newVal.robotType == "ROBOT_TYPE_25") {
            this.adminList = [{
              title: "机器人",
              label1: "重启程序",
              cmd1: "reboot",
              label2: "查询版本",
              cmd2: "version",
              elementLeft: 'robot-btn-reboot-software',
              elementRight: 'robot-btn-query'
            },
            {
              title: "充电",
              label1: "开启",
              cmd1: "chargeopen",
              label2: "关闭",
              cmd2: "chargeclose",
              elementLeft: 'robot-btn-charge',
              elementRight: 'robot-btn-charge'
            },{
              title: "摄像头",
              label1: "开启",
              cmd1: "camerapowon",
              label2: "关闭",
              cmd2: "camerapowoff",
              elementLeft: 'robot-btn-camera',
              elementRight: 'robot-btn-camera'
            }]
          } else if (newVal.robotType === 'ROBOT_TYPE_DISTRIBUTED' || this.chooseRobot.robotType ==='ROBOT_TYPE_DISTRIBUTED') {
            this.adminList = [{
                title: "机器人",
                type: "normal",
                label1: "重启程序",
                cmd1: "reboot",
                label2: "查询版本",
                cmd2: "version",
                elementLeft: 'robot-btn-reboot-software',
                elementRight: 'robot-btn-query'
              },
              {
                title: "奇臂电源",
                type: "openClose",
                branch: "ODD",
                property: "oddState",
                status: false,
                btn1_isNeedDialog: false,
                btn2_isNeedDialog: true,
                elementLeft: 'robot-btn-odd-on',
                elementRight: 'robot-btn-odd-off'
              },
              {
                title: "偶臂电源",
                type: "openClose",
                branch: "EVEN",
                property: "evenState",
                status: false,
                btn1_isNeedDialog: false,
                btn2_isNeedDialog: true,
                elementLeft: 'robot-btn-even-on',
                elementRight: 'robot-btn-even-off'
              },
              {
                title: "点对点图传",
                type: "openClose",
                branch: "TP",
                property: "btdState",
                status: false,
                btn1_isNeedDialog: false,
                btn2_isNeedDialog: true,
                elementLeft: 'robot-btn-tp-on',
                elementRight: 'robot-btn-tp-off'
              },
              {
                title: "NX视觉",
                type: "openClose",
                branch: "NX",
                property: "nxState",
                status: false,
                elementLeft: 'robot-btn-nx-on',
                elementRight: 'robot-btn-nx-off'
              },
              {
                title: "YT云台",
                type: "openClose",
                branch: "PTZ",
                property: "ytState",
                status: false,
                elementLeft: 'robot-btn-ptz-on',
                elementRight: 'robot-btn-ptz-on'
              },
              {
                title: "AUX 24V电源(底部测距, 载波, 激光雷达, BMS通信)",
                type: "openClose",
                branch: "AUX24V",
                property: "auxState",
                label1: "开启",
                label2: "唤醒",
                status: false,
                elementLeft: 'robot-btn-aux24v-on',
                elementRight: 'robot-btn-aux24v-off'
              },
              {
                title: "TOF 测距",
                type: "openClose",
                branch: "TOF",
                property: "tofState",
                label1: "开启",
                label2: "唤醒",
                status: false,
                elementLeft: 'robot-btn-tof-on',
                elementRight: 'robot-btn-tof-off'
              },
              {
                title: "KSM 载波",
                type: "openClose",
                branch: "KSM",
                property: "ksmState",
                label1: "开启",
                label2: "唤醒",
                status: false,
                elementLeft: 'robot-btn-ksm-on',
                elementRight: 'robot-btn-ksm-off'
              },
              {
                title: "Laser 激光雷达",
                type: "openClose",
                branch: "LASER",
                property: "laserState",
                label1: "开启",
                label2: "唤醒",
                status: false,
                elementLeft: 'robot-btn-laser-on',
                elementRight: 'robot-btn-laser-off'
              },
              {
                title: "奇臂视觉采集电源",
                type: "openClose",
                branch: "ODDVIS",
                property: "oddXiLinxState",
                label1: "开启",
                label2: "唤醒",
                status: false,
                elementLeft: 'robot-btn-oddvis-on',
                elementRight: 'robot-btn-evenvis-off'
              },
              {
                title: "偶臂视觉采集电源",
                type: "openClose",
                branch: "EVENVIS",
                property: "evenXiLinxState",
                label1: "开启",
                label2: "唤醒",
                status: false,
                elementLeft: 'robot-btn-evenvis-on',
                elementRight: 'robot-btn-laser-off'
              },
              {
                title: "重启",
                type: "notNeedAuth",
                label2: "睡眠",
                cmd2: "sleep",
                label1: "唤醒",
                cmd1: "wakeup",
                status: false,
                property: "powerState",
                btn1_isNeedDialog: false,
                btn2_isNeedDialog: true,
                elementLeft: 'robot-btn-wakeup',
                elementRight: 'robot-btn-sleep'
              }];
          } else {
            this.adminList = [{
                title: "机器人",
                label1: "重启程序",
                cmd1: "reboot",
                label2: "查询版本",
                cmd2: "version",
                elementLeft: 'robot-btn-reboot-software',
                elementRight: 'robot-btn-query'
              },
              {
                title: "充电",
                label1: "开启",
                cmd1: "chargeopen",
                label2: "关闭",
                cmd2: "chargeclose",
                elementLeft: 'robot-btn-charge',
                elementRight: 'robot-btn-charge'
              },
              {
                title: "机构系统",
                label1: "开启",
                cmd1: "mechpowon",
                label2: "关闭",
                cmd2: "mechpowoff",
                elementLeft: 'robot-btn-mech',
                elementRight: 'robot-btn-mech'
              },
              {
                title: "板载",
                label1: "开启",
                cmd1: "carrierpowon",
                label2: "关闭",
                cmd2: "carrierpowoff",
                elementLeft: 'robot-btn-carrier',
                elementRight: 'robot-btn-carrier',
              },
              {
                title: "摄像头",
                label1: "开启",
                cmd1: "camerapowon",
                label2: "关闭",
                cmd2: "camerapowoff",
                elementLeft: 'robot-btn-camera',
                elementRight: 'robot-btn-camera'
              },
              {
                title: "风扇",
                label1: "开启",
                cmd1: "fanon",
                label2: "关闭",
                cmd2: "fanoff",
                elementLeft: 'robot-btn-fan',
                elementRight: 'robot-btn-fan'
              }
            ]
          }   
        }


        if(!this.session) {
          this.loginH5s();
        }
        if (newVal.robotType === 'ROBOT_TYPE_DISTRIBUTED') {
          let result = this.getAllOnlineRobotList.find(robotItem => {
            return robotItem.robotMac === newVal.robotMac;
          });
          if (result) {
            getCameraListByRobotIdApi({
              robotId: result.robotId
            }).then(res => {
              if (res.code === "000000") {
                this.cameraList = res.data;
              } else {
                this.$message.error(res.data);
              }
            }).catch(err => {
              console.log(err);
            })
          }
        }
      },
      deep: true,
      immediate: true
    },

    /*
     * @Date 2022/06/27 11:23:24
     * 监听视觉模式是否正在运行
     */
    "keepalive.navigation": {
      handler(newValue, oldValue) {
        console.log(newValue);
        for(let key in newValue) {
          if(newValue.hasOwnProperty(key)) {
            if(newValue[key] != oldValue[key]) {
              if(newValue.dir == -1 && newValue.state == -1) {
                this.changeVideoStatus({
                  videoStatus: "",
                  modePlayStatus: 0
                })
              }else {
                this.changeVideoStatus({
                  videoStatus: newValue.dir,
                  modePlayStatus: newValue.state
                })
                switch(newValue.state) {
                  case 0: 
                    this.$message.success("当前处于手动模式");
                    break;
                  case 1:
                    this.$message.success("当前处于录制模式");
                    break;
                  case 2:
                    this.$message.success("当前处于数据库模式");
                    break;
                  case 3:
                    this.$message.success("当前处于纯视觉模式");
                    break;
                  default: 
                    this.$message.success("当前状态未知");
                }
                this.changeModeTab(1)                
              }
              break;
            }
          }
        }
      },
      deep: true
    },

    socketContent: {
      handler(newVal) {
        if (newVal?.data?.pcu) {
          if (this.currentLoginedRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED' || this.chooseRobot.robotType ==='ROBOT_TYPE_DISTRIBUTED') {
            this.adminList.forEach(item => {
              item.status = newVal.data.pcu[item.property];
            })
          }
        }
      },
      deep: true,
    }
  },

  created() {
    this.getAllRobotList();
    this.getOnlineRobotList();
  },

  mounted() {
    showLoading();
    setTimeout(() => {
      closeLoading();
    }, 3000)
  }
};
</script>
<style lang="scss" scoped>
.professionalControl-page {
  height: calc(100% - 5vh);
  padding: 0 1vw;
  color: #fff;
  .top-router {
    padding: 0 1vw;
    position: relative;
  }
  .main {
    height: calc(100% - 5vh);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      width: 13.6vw;
      height: 88vh;
      .robot-list {
        margin-bottom: 2vh;
        .sub-title {
          padding: 1vh 0;
          font-size: 1.3vh;
          display: flex;
          justify-content: space-around;
        }
        ul {
          background-color: rgba(0, 0, 0, 0.5);
          height: 33vh;
          padding: 0;
          margin: 0;
          overflow-y: auto;
          li {
            padding: 0.5vh 0.5vh;
            list-style: none;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .robotName {
              width: 80%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            // .robotName:hover::after {
            //   content: attr(data-title);
            //   display: inline-block;
            //   padding: 10px 14px;
            //   border: 1px solid #ddd;
            //   border-radius: 5px;
            //   color: #333;
            //   background-color: #EEE;
            //   position: fixed;
            //   width: 250px;
            //   line-height:18px;
            //   white-space: pre-wrap;
            // }
            .kr-normal-button {
              padding: 0.5vh;
              cursor: pointer;
            }
          }
          .not-online {
            color: #aaa;
          }
          .choose-line{
            border-left: 2px solid #409EFF;
            color: #409EFF;
            background: linear-gradient(90deg, #008cff80, rgba(228, 255, 0, 0));
          }
        }
      }
      .log-admin {
        justify-content: space-between !important;
        .el-button {
          padding: 1vh;
          .el-icon-picture-outline {
            font-size: 1.4vh !important;
          }
        }
      }
      .log-admin-list {
        background-color: rgba(0, 0, 0, 0.5);
        height: 40vh;
        .admin {
          height: 100%;
          overflow-y: auto;
          .admin-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            height: 5vh;
            padding: 0.2604vw;
            border-bottom: 1px solid #028f6d;
            .admin-title {
              width: 100%;
              text-align: left;
              height: 2vh;
              font-size: 1vh;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .icon {
              font-size: 1.3vh;
              margin-right: 0.5208vw;
            }
            .btn-group {
              height: 0;
              flex: 1;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
        }
        ul {
          padding: 0;
          margin: 0;
          li {
            padding: 1vh 0.5vh;
            list-style: none;
            font-size: 1.3vh;
            display: flex;
            justify-content: space-around;
          }
        }
        .reset{
          // padding: 2vh 1vh;
          .tips{
            margin: 15px 0;
            color: red;
          }
          .reset-flex{
            display: flex;
            margin-bottom: 3vh;
            .reset-flex-left{

            }
            .reset-flex-right{
              margin-left: auto;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 13.6vw;
      height: 88vh;
      background-color: rgba(0, 0, 0, 0.5);

      .arm-status {
        width: 100%;
        // height: 18%;
      }

      .mode {
        width: 100%;
        // height: 82%;

        .baseInfo-ctrl {
          justify-content: space-between !important;
          .el-button {
            padding: 1vh;
            .el-icon-picture-outline {
              font-size: 1.4vh !important;
            }
          }
          .tab-item {
            flex: 1;
            text-align: center;
            cursor: pointer;
          }
        }
        .professional-ctrl {
          padding: 0vh 0.5vh;
          font-size: 1.3vh;
          .sub-title {
            padding-bottom: 0.5vh;
          }
          .robot-control {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
            .robot-photo {
              width: 100%;
              height: 12vh;
              box-sizing: border-box;
            }
            .robot-button {
              width: 100%;
              height: 6vh;
              box-sizing: border-box;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }
          }
          .cloud-control {
            // border: 1px solid red;
            box-sizing: border-box;
            width: 100%;
            height: 22vh;
          }
        }
      }
    }
    .center {
      width: 69vw;
      height: 86vh;
      padding-top: 2vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .center-video {
        width: 100%;
        height: 100%;
        .top-arm-video {
          height: 50%;
          width: 100%;
          border: 1px solid #0aaf8860;
        }
        .visible-infrared-video {
          height: 50%;
          width: 100%;

          display: flex;
          justify-content: center;
          align-items: center;
          .visible-video {
            width: 50%;
            height: 100%;
            border: 1px solid #0aaf8860;
          }
          .infrared-video {
            width: 50%;
            height: 100%;
            border: 1px solid #0aaf8860;
          }
        }
      }
    }
  }
}
.li-actived {
  color: #e4ff00;
  border-left: 2px solid #e4ff00;
  color: #e4ff00;
  background: linear-gradient(90deg, rgba(228, 255, 0, 0.5), rgba(228, 255, 0, 0));
}
.tab-button{
  display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
}
</style>
