<template>
   <div v-if="isShow" v-loading="resetLoading"  element-loading-text="查询/更新继电器状态中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" class="tips">(重启模块只有轻量化巡检机器人才可启用)
      <el-descriptions class="restart-description" :column="1" size="small" >
        <el-descriptions-item label="当前机器 ">
          {{currentRobotRst.robotName||'请选择机器人'}}
        </el-descriptions-item>
        <el-descriptions-item label="重启模块名称 ">
          {{currentRobotRst.restartModuleName||'尚未绑定重启模块'}}
        </el-descriptions-item>
          <el-descriptions-item label="重启模块MAC ">
          {{currentRobotRst.restartModuleMac||'尚未绑定重启模块'}}
        </el-descriptions-item>
        <el-descriptions-item label="重启在线状态 ">
            <el-tag size="small" :type="slightRobotRstStatusTag">{{slightRobotRstStatusText}}</el-tag>
            <i style="font-size: 1.5em; margin-left: 1vw" size="small" class="el-icon-refresh" title="刷新重启模块状态" @click="getOnlineStatus" />
        </el-descriptions-item>
        <el-descriptions-item label="继电器开关状态 " v-if="$hasElement('robot-btn-reboot-robot')">
          <el-switch v-model="slightRobotRstRelayStatus" @change="changeRelaySwitch" :disabled="slightRobotRstDisable">
          </el-switch>
        </el-descriptions-item>
      </el-descriptions>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {getStatus, setStatus } from "@/http/professionalControlApi.js";
export default {
   name: "slightRobotRestart",
  data() {
    return {
      /*
       * @Date 2022/07/07 14:41:44
       * 加载数据中
       */
      resetLoading: false, 
      getSlightRobotRstStatusTimeout: 0, // 查询25kg机器人重启模块超时标识


      /*
       * @Date 2022/07/07 14:42:03
       * 重启模块信息
       */
      slightRobotRstStatus: "online",   // 25KG的机器在线状态
      slightRobotRstRelayStatus: false, // 25KG的重启模块继电器状态：继电器开-机器关机，继电器管，机器关机
      getStatusBackTime: 0              // 记录发起获得状态的时间戳，用于判断这次的获取状态的请求有没有返回
    }
  },
  props: {

    /*
     * @Date 2022/06/16 16:30:02
     * 是否显示当前组件
     */
    isShow: {
      type: Boolean,
      default: () => false,
      require: true
    },

    /*
     * @Date 2022/06/16 16:30:21
     * 当前重启模块MAC
     */
    currentRobotRst: {
      type: Object,
      default: () => { return {
        robotName: "",
        restartModuleName: "",
        restartModuleMac: ""
        }
      },
      require: true,
    }

  },

  computed: {
    /*
     * @Date 2022/06/17 10:50:53
     * 缓存中的重启模块数据
     */
    ...mapState(["slightRobotRst"]),

     /**
    * @Author KR0132
    * @Date 2022/06/01 13:14:52
    * @Description 25kg重启模块开关状态
    */
    slightRobotRstStatusTag: function() {

      if(!this.slightRobotRstStatus) {
        return "error";
      }
      if (this.slightRobotRstStatus=="online") {
        return "success";
      }else if(this.slightRobotRstStatus=="offline") {
        return "warning";
      }else {
        return "error";
      }
    },

    /**
    * @Author KR0132
    * @Date 2022/06/01 13:14:52
    * @Description 25KG重启模块状态描述
    */
    slightRobotRstStatusText: function() {

      if(!this.slightRobotRstStatus) {
        return "查询失败";
      }
      
      if (this.slightRobotRstStatus=="online") {
        return "在线";
      }else if(this.slightRobotRstStatus=="offline") {
        return "离线";
      }else {
        return "查询失败";
      }
    },

    /**
    * @Author KR0132
    * @Date 2022/06/01 13:14:52
    * @Description 继电器允许设置状态
    */
    slightRobotRstDisable: function() {
      return !(this.slightRobotRstStatus=="online" && !this.resetLoading);
    }
  },

  watch: {
    /**
     * @Author KR0132
     * @Date 2022/06/16 17:22:38
     * @Description 监听切换设备事件
     */
    currentRobotRst: function() {
      if(this.currentRobotRst && this.currentRobotRst.restartModuleMac) {
        this.getOnlineStatus();
      }else {
        this.slightRobotRstStatus = "";
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/06/17 10:51:27
     * @Description 监听后台响应重启模块状态变化
     */
    slightRobotRst: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.isResponse) {
          if(newValue.rstMac == this.currentRobotRst.restartModuleMac) {
            this.resetLoading = false;
            this.getSlightRobotRstStatusTimeout = new Date().getTime();
            this.slightRobotRstStatus = "online";
            switch(newValue.option) {
              case "SET":
                this.$message.success(newValue.result.indexOf("ON")>-1 ? '开启继电器成功': '关闭继电器成功');
                break;
              case "GET":
                this.$message.success(newValue.result.indexOf("ON")>-1 ? '查询继电器状态为开': '查询继电器状态为关');
                break;
              case "TIMEOUT":
                this.$message.error('请求重启模块超时！');
                this.slightRobotRstStatus = "offline";
                break;
              default:
            }
          }
        }
      }
    }
   
  },


  methods: {

    /**
     * @Author KR0288
     * @Date 2022/06/06 08:54:49
     * @Description 获取重启模块在线状态
     */
    getOnlineStatus(){
      try{
        this.resetLoading = true
        this.isCheckStatus = false
        const getStatusBackTime = this.getStatusBackTime = new Date().getTime()
        setTimeout(()=>{
          if(getStatusBackTime === this.getStatusBackTime) {
            this.$busEvent.$emit('RESPONSE_WAIT_TIMEOUT',{result:'请求超時'})
          }
          this.resetLoading = false;
        },15000)

        /*
         * @Date 2022/06/17 13:56:27
         * 更新vuex 中 slightRobotRst 中监听的设备
         */
        this.$store.commit("updateCurrentSlightRobotRst", {rstMac: this.currentRobotRst.restartModuleMac});
        
        getStatus({
          diskMac:'212322',
          rstName:this.currentRobotRst.restartModuleName,
          rstMac: this.currentRobotRst.restartModuleMac
        }).then(res => {
          this.handleRstHttpResponse(res);
        })
      }catch(err){
        console.log("🚀 ~ file: professionalControl.vue ~ line 562 ~ getOnlineStatus ~ err", err)
        this.getStatusBackTime = 0
        this.$message.error(err.mesg||err.mesg);
        this.relaySwitch = false
        this.resetLoading = false
      }
    },

     /**
     * @Author KR0288
     * @Date 2022/06/06 08:57:18
     * @Description 操作继电器开关
     */
    async changeRelaySwitch(sleep){

      let message = "继续此操作将会".concat(sleep ? "休眠" : "唤醒", "机器人("+this.currentRobotRst.robotName +"), 是否继续?");
      this.$confirm(message, '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          try{
            this.resetLoading = true
             setStatus({
              diskMac:'212322',
              rstMac:this.currentRobotRst.restartModuleMac,
              isSleep: sleep,
              isOpen:true
            }).then(res => {

              this.handleRstHttpResponse(res);
              this.getOnlineStatus()
            })
          
          }catch(err){
            console.log("🚀 ~ file: professionalControl.vue ~ line 562 ~ getOnlineStatus ~ err", err)
            this.$message.error(err.data||err.mesg);
          }
        }).catch(() => {
          this.slightRobotRstRelayStatus = !this.slightRobotRstRelayStatus;
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
    },

    /**
     * @Author KR0132
     * @Date 2022/06/16 17:44:09
     * @Description 处理查询和设置继电器的HTTP响应
     */
    handleRstHttpResponse(res) {
      if(res.code != "000000") {
        this.$message.info(res.data||res.mesg);
      }else {
        if(res.data.indexOf("不在线")>-1) {
          this.slightRobotRstStatus = "offline";
          this.$message.warning(res.data);
          this.resetLoading = false
        }else {
          this.slightRobotRstStatus = "online";
        }
      }
    }
  }
}
</script>

<style>

</style>