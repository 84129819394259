<template>
  <div class="robotArmControl-component">
    <el-tabs v-model="typeActive">
      <el-tab-pane label="奇臂" name="odd">
        <div class="arm-action">
          <div class="item">
            <div class="label">速 度</div>
            <div class="value-slider">
              <el-slider v-model="robotWalkSpeed" :min="speed.minSpeed" :max="speed.maxSpeed" @change="robotSpeedChange"></el-slider>
            </div>
          </div>
          <div class="item posi">
            <div class="label">位 置</div>
            <div class="value-slider">
              <el-slider v-model="oddPosition" :min="location.minLocation" :max="location.maxLocation" @change="robotCountChange"></el-slider>
            </div>
            <el-checkbox v-model="usePositionMode">启用</el-checkbox>
          </div>
          <div class="item">
            <div class="label">压 紧</div>
            <div class=" press-type value-slider">
              <el-radio-group v-model="oddPressType">
                <el-radio :label="0">默认</el-radio>
                <el-radio :label="1" v-if="$hasElement('robot-btn-lightlypressup')">护线</el-radio>
                <el-radio :label="2" v-if="$hasElement('robot-btn-currentpressup')">电流</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="item">
            <div class="label">电 流</div>
            <div class="value-slider"><el-slider v-model="oddCurrent" :min="currentMin" :max="currentMax"></el-slider></div>
          </div>
          <div class="button-group">
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="oddArmActFun('oddlock')" v-if="$hasElement('robot-btn-odd')">锁 臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="oddArmActFun('oddunlock')" v-if="$hasElement('robot-btn-odd')">松 臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="pressupBtn" v-if="$hasElement('robot-btn-odd')">压 紧</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="pressDownBtn" v-if="$hasElement('robot-btn-odd')">松 开</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="innerShrinkFun('oddinnershrink')" v-if="$hasElement('robot-btn-arm')">内碰收臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="outereExtendFun('oddouterextend')" v-if="$hasElement('robot-btn-arm')">外碰展臂</el-button>
                        <el-button :disabled="getIsDisabled" class="normal-small-button" @click="shrinkExtendArmFun('shrink')" v-if="$hasElement('robot-btn-shrink')">收臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="shrinkExtendArmFun('extend')" v-if="$hasElement('robot-btn-extend')">展臂</el-button>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="偶臂" name="even">
        <div class="arm-action">
          <div class="item">
            <div class="label">速 度</div>
            <div class="value-slider">
              <el-slider v-model="robotWalkSpeed" :min="speed.minSpeed" :max="speed.maxSpeed" @change="robotSpeedChange"></el-slider>
            </div>
          </div>

          <div class="item posi">
            <div class="label">位 置</div>
            <div class="value-slider"><el-slider v-model="evenPosition" :min="location.minLocation" :max="location.maxLocation" @change="robotCountChange"></el-slider></div>
            <el-checkbox v-model="usePositionMode">启用</el-checkbox>
          </div>

          <div class="item">
            <div class="label">压 紧</div>
            <div class=" press-type value-slider">
              <el-radio-group v-model="evenPressType">
                <el-radio :label="0">默认</el-radio>
                <el-radio :label="1" v-if="$hasElement('robot-btn-lightlypressup')">护线</el-radio>
                <el-radio :label="2" v-if="$hasElement('robot-btn-currentpressup')">电流</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="item">
            <div class="label">电 流</div>
            <div class="value-slider">
              <el-slider v-model="evenCurrent" :min="currentMin" :max="currentMax"></el-slider>
              </div>
          </div>

          <div class="button-group">
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="evenArmActFun('evenlock')" v-if="$hasElement('robot-btn-even')">锁 臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="evenArmActFun('evenunlock')" v-if="$hasElement('robot-btn-even')">松 臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="pressupBtn" v-if="$hasElement('robot-btn-even')">压 紧</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="pressDownBtn" v-if="$hasElement('robot-btn-even')">松 开</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="innerShrinkFun('eveninnershrink')" v-if="$hasElement('robot-btn-arm')">内碰收臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="outereExtendFun('evenouterextend')" v-if="$hasElement('robot-btn-arm')">外碰展臂</el-button>
                        <el-button :disabled="getIsDisabled" class="normal-small-button" @click="shrinkExtendArmFun('shrink')" v-if="$hasElement('robot-btn-shrink')">收臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="shrinkExtendArmFun('extend')" v-if="$hasElement('robot-btn-extend')">展臂</el-button>
          </div>

        </div>
      </el-tab-pane>

      <el-tab-pane label="双臂" name="double">
        <div class="arm-action">
          <div class="item">
            <div class="label">速 度</div>
            <div class="value-slider">
              <el-slider v-model="robotWalkSpeed" :min="speed.minSpeed" :max="speed.maxSpeed" @change="robotSpeedChange"></el-slider>
            </div>
          </div>

          <div class="item posi">
            <div class="label">位 置</div>
            <div class="value-slider"><el-slider v-model="doublePosition" :min="100" :max="6900" @change="robotCountChange"></el-slider></div>
            <el-checkbox v-model="usePositionMode">启用</el-checkbox>
          </div>

          <div class="item">
            <div class="label">压 紧</div>
            <div class=" press-type" style="width:10vw">
              <el-radio-group v-model="doublePressType">
                <el-radio :label="11" v-if="$hasElement('robot-btn-press')">完全</el-radio>
                <el-radio :label="22" v-if="$hasElement('robot-btn-press')">小步</el-radio>
                <el-radio :label="33" v-if="$hasElement('robot-btn-lightlypressup')">护线</el-radio>
                <el-radio :label="44" v-if="$hasElement('robot-btn-currentpressup')">电流</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="item">
            <div class="label">电 流</div>
            <div class="value-slider"><el-slider v-model="doubleCurrent" :min="currentMin" :max="currentMax"></el-slider></div>
          </div>

          <div class="button-group">
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="pressupBtn" v-if="$hasElement('robot-btn-press')">压 紧</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="pressDownBtn" v-if="$hasElement('robot-btn-press')">松 开</el-button>
                        <el-button :disabled="getIsDisabled" class="normal-small-button" @click="shrinkExtendArmFun('shrink')" v-if="$hasElement('robot-btn-shrink')">收臂</el-button>
            <el-button :disabled="getIsDisabled" class="normal-small-button" @click="shrinkExtendArmFun('extend')" v-if="$hasElement('robot-btn-extend')">展臂</el-button>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="机器人" name="robot">
        <div class="button-group">
          <el-upload
            class="upload-demo"
            :headers="{ Authorization: Authorization,'Ctrl-Authorization': currentLoginedRobot.robotControlToken }"
            :show-file-list="false"
            :action="config.ctrlRobotURL + '/eprdms/control/common/upgrade'"
            :file-list="fileList"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :before-upload="beforeUpload"
          >
            <el-button :disabled="getIsDisabled" class="normal-small-button">上传方案</el-button>
          </el-upload>
        </div>
      </el-tab-pane>

      <el-tab-pane label="摄像头" name="camera" v-if="currentLoginedRobot && currentLoginedRobot.robotType === 'ROBOT_TYPE_DISTRIBUTED'">
        <div class="btn-item" v-for="item in cameraBtnList" :key="item.id">
          <div class="title">{{ item.title }}</div>
          <div class="content">
            <Icon :name="item.status ? 'warn' : 'normal'" />
            <el-button class="normal-small-button" @click="handleCameraSwitch(item, 'open')" v-if=$hasElement(item.openRight)>开启</el-button>
            <el-button class="normal-small-button" @click="handleCameraSwitch(item, 'close')" v-if=$hasElement(item.closeRight)>关闭</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { 
  currentpressup,
  evenArmAct, 
  oddArmAct, 
  innershrink, 
  outerextend, 
  lightlypressup, 
  pressArm,
  setShrinkExtendApi,
  switchCameraApi
} from "@/http/professionalControlApi.js";
import { mapState, mapGetters } from "vuex";
import config from "@/http/config.js";
import Icon from "@/components/Icon.vue";

export default {
  name: "robotArmControl",

  components: {
    Icon
  },

  data() {
    return {
      robotWalkSpeed: 300,    // 机器人运动速度
      typeActive: "odd",      // 手臂类型
      Authorization: sessionStorage.getItem("Authorization"),
      config: config,
      usePositionMode: false, // 是否用户模式
      fileList: [],

      /*
       * @Date 2022/07/08 11:35:24
       * 奇臂
       */
      oddPressType: 0, // 奇臂压紧类型
      oddPosition: 10, // 奇臂位置
      oddCurrent: 100, // 奇臂电流


      /*
       * @Date 2022/07/08 11:36:36
       * 偶臂
       */
      evenPressType: 0, // 偶臂类型
      evenPosition: 10, // 偶臂位置
      evenCurrent: 100, // 偶臂电流


      /*
       * @Date 2022/07/08 11:36:50
       * 双臂
       */
      doublePosition: 10,
      doubleCurrent: 100,   // 双臂电流
      robotArmSpeed: 18000, // 双臂速度
      doublePressType: 11,  // 双臂类型


      /**
       * @description: 摄像头
       * @date 2023-09-05 13:59
       */
      cameraBtnList: [
        {
          id: "ODD_NAV",
          property: "oddNavigationCameraState",
          title: "奇臂导航",
          openRight: "robot-btn-oddnav-on",
          closeRight: "robot-btn-oddnav-off",
          status: false
        },
        {
          id: "ODD_WHEEL",
          property: "oddWheelCameraState",
          title: "奇臂观臂",
          openRight: "robot-btn-oddwheel-on",
          closeRight: "robot-btn-oddwheel-off",
          status: false
        },        
        {
          id: "ODD_INSIDE",
          property: "oddInsideCameraState",
          title: "奇臂内侧",
          openRight: "robot-btn-oddoutside-on",
          closeRight: "robot-btn-oddoutside-off",
          status: false
        },
        {
          id: "ODD_OUTSIDE",
          property: "oddOutsideCameraState",
          title: "奇臂外侧",
          openRight: "robot-btn-oddoutside-on",
          closeRight: "robot-btn-oddoutside-off",
          status: false
        },
        {
          id: "ODD_TOF",
          property: "oddTofCameraState",
          title: "奇臂TOF",
          openRight: "robot-btn-oddtof-on",
          closeRight: "robot-btn-oddtof-off",
          status: false
        },
        {
          id: "EVEN_NAV",
          property: "evenNavigationCameraState",
          title: "偶臂导航",
          openRight: "robot-btn-evennav-on",
          closeRight: "robot-btn-evennav-off",
          status: false
        },
        {
          id: "EVEN_WHEEL",
          property: "evenWheelCameraState",
          title: "偶臂观臂",
          openRight: "robot-btn-evenwheel-on",
          closeRight: "robot-btn-evenwheel-off",
          status: false
        },
        {
          id: "EVEN_INSIDE",
          property: "evenInsideCameraState",
          title: "偶臂内侧",
          openRight: "robot-btn-eveninside-on",
          closeRight: "robot-btn-eveninside-off",
          status: false
        },
        {
          id: "EVEN_OUTSIDE",
          property: "evenOutsideCameraState",
          title: "偶臂外侧",
          openRight: "robot-btn-evenoutside-on",
          closeRight: "robot-btn-evenoutside-off",
          status: false
        },
        {
          id: "EVEN_TOF",
          property: "evenTofCameraState",
          title: "偶臂TOF",
          openRight: "robot-btn-eventof-on",
          closeRight: "robot-btn-eventof-off",
          status: false
        }
      ]
    };
  },

  computed: {
    ...mapState(["currentLoginedRobot", "socketContent"]),
    ...mapGetters(["getIsDisabled"]),

    /**
     * @description: 速度
     * @date 2023-07-05 11:11
     */
    speed() {
      let minSpeed = 0;
      let maxSpeed = 100;
      switch (this.currentLoginedRobot?.robotType) {
        case "ROBOT_TYPE_25":
        case "ROBOT_TYPE_50":
          minSpeed = 300;
          maxSpeed = 8000;
          break;

        case "ROBOT_TYPE_DISTRIBUTED":
          minSpeed = 100;
          maxSpeed = 9000;
          break;

        default:
        minSpeed = 300;
          maxSpeed = 8000;
      }

      console.log({
        minSpeed,
        maxSpeed
      });
      return {
        minSpeed,
        maxSpeed
      }
    },

    /**
     * @description: 位置
     * @date 2023-07-05 11:12
     */
    location() {
      let minLocation = 0;
      let maxLocation = 1000;
      switch (this.currentLoginedRobot?.robotType) {
        case "ROBOT_TYPE_25":
        case "ROBOT_TYPE_50":
          minLocation = 100;
          maxLocation = 6900;
          break;

        case "ROBOT_TYPE_DISTRIBUTED":
          minLocation = 0;
          maxLocation = 36000;
          break;

        default:
        minLocation = 100;
        maxLocation = 6900;
      }
      return {
        minLocation,
        maxLocation
      }
    },


    /*
     * @Date 2022/07/08 11:42:56
     * 50kg 电流范围 50-800
     */
    currentMin() {
      let minCurrent = 0;
      switch(this.currentLoginedRobot?.robotType) {
        case "ROBOT_TYPE_25":
        case "ROBOT_TYPE_DISTRIBUTED":
          minCurrent = 100;
          break;
        case "ROBOT_TYPE_50":
          minCurrent = 50;
          break;
        default:
          minCurrent = 100;
      }
      return minCurrent
    },

    /*
     * @Date 2022/07/08 11:43:16
     * 25kg 电流范围 100-6000
     */
    currentMax() {
      let maxCurrent = 0;
      switch(this.currentLoginedRobot?.robotType) {
        case "ROBOT_TYPE_25":
        case "ROBOT_TYPE_DISTRIBUTED":
          maxCurrent = 6000;
          break;
        case "ROBOT_TYPE_50":
          maxCurrent = 500;
          break;
        default:
          maxCurrent = 6000;
      }
      return maxCurrent
    }

  },

  watch: {
    /*
     * @Date 2022/07/08 11:48:58
     * 监听启用运动模式还是位置模式
     */
    usePositionMode(newVal) {
      if (newVal) {
        this.$store.commit("updateRobotMode", 2);
      } else {
        this.$store.commit("updateRobotMode", 1);
      }
    },

    /**
     * @description: 监听robotState的fpga(摄像头充电组状态)
     * @date 2023-09-05 16:30
     */
    socketContent: {
      handler(newVal, oldVal) {
        if (newVal?.data.fpga) {
          this.cameraBtnList.forEach(cameraBtnItem => {
            cameraBtnItem.status = newVal.data.fpga[cameraBtnItem.property]
          })
        }
      },
      deep: true
    }
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/07/08 11:52:40
     * @Description 机器人速度改变
     */
    robotSpeedChange(speed) {
      this.$message.success("速度修改成功!")
      this.$store.commit("updateRobotVelocity", speed);
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 11:52:50
     * @Description 机器人位置count改变
     */
    robotCountChange(count) {
      this.$message.success("位置修改成功!")
      this.$store.commit("updateRobotCount", count);
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 13:56:26
     * @Description 奇臂压紧、松开、锁臂和松臂 
     * 奇臂压紧oddpressup
     * 奇臂松开oddpressdown
     * 奇臂锁臂oddlock
     * 奇臂松臂oddunlock
     */
    oddArmActFun(cmd) {
      oddArmAct({
        cmd: cmd,
        data: {
          count: this.currentLoginedRobot.count,
          model: this.currentLoginedRobot.model,
          velocity: this.robotArmSpeed
        }
      }).then(res => {
        if (res.code == "000000") {
          this.$message.success(res.mesg);
        } else {
          this.$message.error(res.mesg);
        }
      }).catch(error => {
        console.log(error);
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 13:58:42
     * @Description 偶臂压紧、松开、锁臂和松臂
     * 偶臂压紧evenpressup
     * 偶臂松开evenpressdown
     * 偶臂锁臂evenlock
     * 偶臂松臂evenunlock
     */
    evenArmActFun(cmd) {
      evenArmAct({
        cmd: cmd,
        data: {
          count: this.currentLoginedRobot.count,
          model: this.currentLoginedRobot.model,
          velocity: this.robotArmSpeed
        }
      }).then(res => {
        if (res.code == "000000") {
          this.$message.success(res.mesg);
        } else {
          this.$message.error(res.mesg);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 13:52:59
     * @Description 单臂(默认、护线、电流)压紧、双臂(完全、小步、护线、电流)压紧
     */
    pressupBtn() {
      // 1、先判断是奇臂还是偶臂还是双臂
      if (this.typeActive == "odd") {

        // 2、判断压紧类型
        switch (this.oddPressType) {

          case 0: // 奇臂-默认
            this.oddArmActFun("oddpressup");
            break;

          case 1: // 奇臂-护线
            this.lightlypressupFun("oddlightlypressup");
            break;

          case 2: // 奇臂-电流
            this.currentPressFun("oddcurrentpressup", this.oddCurrent);
            break;

          default:
            this.oddArmActFun("oddpressup");
            break;
        }

      // 偶臂
      } else if (this.typeActive == "even") {
        switch (this.evenPressType) {

          case 0: // 偶臂-默认
            this.evenArmActFun("evenpressup");
            break;

          case 1: // 偶臂-护线
            this.lightlypressupFun("evenlightlypressup");
            break;

          case 2: // 偶臂-电流
            this.currentPressFun("evencurrentpressup", this.evenCurrent);
            break;

          default:
            this.evenArmActFun("evenpressup");
            break;
        }
      
      // 双臂
      } else if (this.typeActive == "double") {
        switch (this.doublePressType) {

          case 11: // 双臂-完全
            this.pressArmFun("completepressup");
            break;

          case 22: // 双臂-小步
            this.pressArmFun("littleressup");
            break;

          case 33: // 双臂-护线
            this.lightlypressupFun("lightlypressup");
            break;

          case 44: // 双臂-电流
            this.currentPressFun("currentpressup", this.doubleCurrent);
            break;

          default:
            this.pressArmFun("completepressup");
            break;
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 14:05:56
     * @Description 单臂，双臂电流压紧
     * 偶臂电流压紧evencurrentpressup
     * 奇臂电流压紧oddcurrentpressup
     * 双臂电流压紧currentpressup
     */
    currentPressFun(cmd, current) {
      currentpressup({ 
        cmd: cmd, 
        current: current,
        type: this.currentLoginedRobot.robotType 
      }).then(res => {
        if (res.code == "000000") {
          this.$message.success(res.mesg);
        } else {
          this.$message.error(res.mesg);
        }
      }).catch(error => {
        console.log(error);
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 16:52:27
     * @Description 单臂双臂轻压护线
     * 偶臂轻压护线evenlightlypressup
     * 奇臂轻压护线oddlightlypressup
     * 双臂轻压护线lightlypressup
     */
    lightlypressupFun(cmd) {
      lightlypressup({ cmd: cmd })
        .then(res => {
          //业务逻辑
          if (res.code == "000000") {
            this.$message.success(res.mesg);
          } else {
            this.$message.error(res.mesg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 13:53:06
     * @Description 松开
     */
    pressDownBtn() {
      if (this.typeActive == "odd") {
        this.oddArmActFun("oddpressdown");
      } else if (this.typeActive == "even") {
        this.evenArmActFun("evenpressdown");
      } else {
        this.pressArmFun("pressdown");
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 16:52:51
     * @Description 双臂完全松开/ 双臂完全压紧 / 双臂小步轻压 
     * 双臂完全松开cmd=pressdown, 
     * 双臂完全压紧cmd=completepressup,
     * 双臂小步轻压cmd=littleressup
     */
    pressArmFun(cmd) {
      pressArm({ cmd: cmd })
        .then(res => {
          //业务逻辑
          if (res.code == "000000") {
            this.$message.success(res.mesg);
          } else {
            this.$message.error(res.mesg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 14:01:25
     * @Description 奇臂/偶臂内碰收臂
     * 偶臂内碰收臂eveninnershrink
     * 奇臂内碰收臂oddinnershrink
     */
    innerShrinkFun(cmd) {
      innershrink({ cmd: cmd })
        .then(res => {
          //业务逻辑
          if (res.code == "000000") {
            this.$message.success(res.mesg);
          } else {
            this.$message.error(res.mesg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 14:02:35
     * @Description 外碰展臂
     * 偶臂内碰展臂evenouterextend
     * 奇臂内碰展臂oddouterextend
     */
    outereExtendFun(cmd) {
      outerextend({ cmd: cmd })
        .then(res => {
          if (res.code == "000000") {
            this.$message.success(res.mesg);
          } else {
            this.$message.error(res.mesg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    /**
     * @description: 展臂、收臂
     * @date 2023-08-17 10:06
     */
    shrinkExtendArmFun(cmd) {
      setShrinkExtendApi({
        cmd: cmd,
        data: {
          model: this.usePositionMode ? 2 : 1,
          velocity: this.robotWalkSpeed
        }
      }).then(res => {
        console.log(res);
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 17:29:21
     * @Description 上传前，检查文件类型，只支持db类型
     */
    beforeUpload(file) {
      console.log("上传之前调用", file);
      var fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (fileType != "db") {
        this.$message.warning("方案上传只支持.db类型文件");
        return false;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 17:30:36
     * @Description 上传成功
     */
    uploadSuccess(response, file, fileList) {
      console.log("上传", response, file, fileList);
      if(response.code!="000000"){
         this.$message.error("上传失败");   
      }else{
        this.$message.success("上传成功");
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/08 17:30:49
     * @Description 上传失败
     */
    uploadError(err, file, fileList) {
      console.log(err, file, fileList);
      this.$message({
        type: "error",
        message: err
      });
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },

    handlePreview(file) {
      console.log(file);
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    /**
     * @description: 摄像头上电开关
     * @date 2023-09-05 17:14
     */
    handleCameraSwitch(switchItem, order) {
      switchCameraApi({
        cameraType: switchItem.id,
        status: order === "open"
      }, {
        Authorization: sessionStorage.getItem("Authorization"),
        Ctrl_Authorization: this.currentLoginedRobot.robotControlToken
      }).then(res => {
        if (res.code === "000000") {
          this.$message.success(res.mesg)
        } else {
          this.$message.error(res.mesg)
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.robotArmControl-component {
  .arm-action {
    .item {
      height: 3vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .label {
        width: 2vw;
      }
      .value-slider {
        width: 8vw;
        margin-right: 0.5vw;
      }
    }
    .button-group {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .btn-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 5.5556vh;
    .title {
      width: 100%;
      height: 1.8519vh;
      line-height: 1.8519vh;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 0;
      width: 100%;

      .icon {
        margin-right: 0.5208vw;
      }
    }
  }

  #pane-camera {
    overflow-y: auto;
    height: 100%;
  }
}
</style>
