<template>
  <div class="visua-mode-box">
    <!-- 录制模式 -->
    <div class="mode-box">
      <div class="mode-title">
        <h3>录制模式</h3>
      </div>
      <div class="mode-form">
        <div class="form-item">
          <el-form :inline="true" :model="formData" class="demo-form-inline">
            <el-form-item label="起始塔号：">
              <el-input v-model="formData.beginnum" placeholder="请输入塔号" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="起始位置：">
              <el-select v-model="formData.position" placeholder="起始位置" size="mini">
                <el-option label="塔前" value="0"></el-option>
                <el-option label="塔内" value="1"></el-option>
                <el-option label="塔后" value="2"></el-option>
                <el-option label="直线" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运动方向：">
              <el-select v-model="formData.direction" placeholder="运动方向" size="mini">
                <el-option label="奇臂" value="1"></el-option>
                <el-option label="偶臂" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- btn btn-disable -->
          <div class="mode-btn">
            <el-button v-if="$hasElement('navigate-btn-record')" type="success" size="mini" @click="startModeBtn" class="kr-normal-button" :class="{'btn-disable':!currentLoginedRobot.robotMac}">开始</el-button>
            <el-button v-if="$hasElement('navigate-btn-endrecord')" type="danger" size="mini" @click="endModeBtn" class="kr-normal-button">结束</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 数据库模式 -->
    <div class="mode-box">
      <div class="mode-title">
        <h3>数据库模式</h3>
      </div>
      <div class="mode-form">
        <div class="form-item">
          <el-form :inline="true" :model="database" class="demo-form-inline">
            <el-form-item label="起始塔号：">
              <el-input v-model="database.beginnum" placeholder="请输入起始塔号" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="结束塔号：">
              <el-input v-model="database.endnum" placeholder="请输入结束塔号" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="视觉方向：">
              <el-select v-model="database.direction" placeholder="视觉方向" size="mini">
                <el-option label="奇臂" value="1"></el-option>
                <el-option label="偶臂" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运动方向：">
              <el-select v-model="database.databaseDirection" placeholder="运动方向" size="mini">
                <el-option label="前进" value="1"></el-option>
                <el-option label="后退" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="起始位置：">
              <el-select v-model="database.position" placeholder="起始位置" size="mini">
                <el-option label="塔前" value="0"></el-option>
                <el-option label="塔内" value="1"></el-option>
                <el-option label="塔后" value="2"></el-option>
                <el-option label="直线" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- btn -->
          <div class="mode-btn">
            <el-button v-if="$hasElement('navigate-btn-database')" type="success" size="mini" @click="startDataBtn" class="kr-normal-button" :class="{'btn-disable':!currentLoginedRobot.robotMac}">开始</el-button>
            <el-button v-if="$hasElement('navigate-btn-enddatabase')" type="danger" size="mini" @click="endDatabaseBtn" class="kr-normal-button">结束</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 纯视觉模式 -->
    <div class="mode-box pure-box">
      <div class="mode-title">
        <h3>纯视觉模式</h3>
      </div>
      <div class="mode-form">
        <div class="form-item">
          <!-- btn -->
          <div class="mode-btn">
            <el-button type="success" size="mini" @click="playPosition(1)" class="kr-normal-button"  :class="{'btn-disable':!currentLoginedRobot.robotMac}">奇臂方向</el-button>
            <el-button type="success" size="mini" @click="playPosition(2)" class="kr-normal-button" :class="{'btn-disable':!currentLoginedRobot.robotMac}">偶臂方向</el-button>
            <el-button type="danger" size="mini" @click="stopPosition" class="kr-normal-button">停止播放</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { postNavigateRecord, postNavigateEndrecord, postNavigateDatabase, postNavigateEndEndrecord, postNavigatePlay, postNavigateStopPlay } from "@/http/professionalControlApi.js";
export default {
  data() {
    return {
      formData: {
        beginnum: "",
        position: "0",
        direction: "1",
      },
      database: {
        beginnum: "",
        endnum: "",
        direction: "1",
        databaseDirection: "",
        position: ""
      },
      modePlayStatus:0,   // 1 录制模式开始 2 数据库模式开始 3 纯视觉模式开始
      playStartModeIng:'',// 正在播放的模式
    };
  },
  computed: {
    ...mapState(["currentLoginedRobot"]),
  },
  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/23 08:34:58
     * @Description 验证是否登陆机器
     */
    verificationlogin() {
      let currentLoginedRobot = this.$store.state.currentLoginedRobot;
      if(currentLoginedRobot.robotMac) {
        return true;
      }else {
        return false;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/22 17:14:55
     * @Description 验证是否正在进行某个模式
     */
    verificationPlay(type) {
      // let modePlayStatus = this.modePlayStatus;
      // if(modePlayStatus == 1 && type!='record') {
      //   this.$alert('必须结束录制模式才能切换其他模式', '提示', {
      //     confirmButtonText: '确定',
      //     customClass:"video-control-alert"
      //   });
      //   return false;
      // }else if(modePlayStatus == 2 && type!='data') {
      //   this.$alert('必须结束数据库模式才能切换其他模式', '提示', {
      //     confirmButtonText: '确定',
      //     customClass:"video-control-alert"
      //   });
      //   return false;
      // }else if(modePlayStatus == 3 && type!='pure') {
      //   this.$alert('必须结束纯视觉模式才能切换其他模式', '提示', {
      //     confirmButtonText: '确定',
      //     customClass:"video-control-alert"
      //   });
      //   return false;
      // }
      return true;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/22 17:15:30
     * @Description 录制模式 开始
     */
    startModeBtn() {
      let verif_login = this.verificationlogin();
      if(!verif_login) return
      let verif_play = this.verificationPlay('record');
      if(!verif_play) return
      
      console.log(this.formData);
      let reg = /^[1-9]\d*$/;
      let beginnum = this.formData.beginnum;
      if (beginnum == "") {
        this.$message.warning("起始塔号不能为空");
        return;
      }

      if (!reg.test(beginnum)) {
        this.$message.warning("请输入有效格式的塔号");
        return;
      }
      let _data = {
        beginnum: this.formData.beginnum,
        position: parseInt(this.formData.position),
        direction: parseInt(this.formData.direction),
      };
      postNavigateRecord(_data).then((res) => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });
          this.modePlayStatus = 1;
          
          // 发送开启视频指令
          this.$emit('changeVideoStatus',{
            videoStatus: _data.direction,
            modePlayStatus: this.modePlayStatus
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/22 17:35:17
     * @Description 录制模式 结束
     */
    endModeBtn() {
      // 需要强制结束
      // let verif_login = this.verificationlogin();
      // if(!verif_login) return
      // if(this.modePlayStatus != 1) {
      //   return
      // }
      postNavigateEndrecord().then((res) => {
        console.log(res);
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });
          this.modePlayStatus = 0;
          // 发送视频指令
          this.$emit('changeVideoStatus',{
            videoStatus: 0,
            modePlayStatus: this.modePlayStatus
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/20 11:36:08
     * @Description 数据库模式--开始
     */
    startDataBtn() {
      let verif_login = this.verificationlogin();
      if(!verif_login) return
      let verif_play = this.verificationPlay('data');
      console.log(verif_play)
      if(!verif_play) return
      let reg = /^[1-9]\d*$/;
      let database = this.database;
      if (database.beginnum == "") {
        this.$message.warning("起始塔号不能为空");
        return;
      }
      if (database.endnum == "") {
        this.$message.warning("结束塔号不能为空");
        return;
      }

      if (!reg.test(database.beginnum) || !reg.test(database.endnum)) {
        this.$message.warning("请输入有效格式的塔号");
        return;
      }

      if(database.databaseDirection == "") {
        this.$message.warning("运动方向不能为空");
        return;
      }

      if(database.position == "") {
        this.$message.warning("起始位置不能为空");
        return;
      }

      let _data = {
        obbeginnum: database.beginnum,
        obendnum: database.endnum,
        direction: parseInt(database.direction),
        databaseDirection: database.databaseDirection,
        position: database.position
      };

      postNavigateDatabase(_data).then((res) => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });

          this.modePlayStatus = 2;
          // 发送开启视频指令
          this.$emit('changeVideoStatus',{
            videoStatus: _data.direction,
            modePlayStatus: this.modePlayStatus
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/22 17:38:02
     * @Description 数据库模式 结束
     */
    endDatabaseBtn() {
      // 需要强制结束
      // let verif_login = this.verificationlogin();
      // if(!verif_login) return
      // if(this.modePlayStatus != 2) {
      //   return
      // }
      postNavigateEndEndrecord().then((res) => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });
          this.modePlayStatus = 0;
          // 发送视频指令
          this.$emit('changeVideoStatus',{
            videoStatus: 0,
            modePlayStatus: this.modePlayStatus
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });
    },
    
    /**
     * @Author KR0288
     * @Date 2022/06/22 17:38:14
     * @Description 纯视频播放--1 奇臂 2 偶臂
     */
    playPosition(direction) {
      let verif_login = this.verificationlogin();
      if(!verif_login) return
      let verif_play = this.verificationPlay('pure');
      console.log(verif_play)
      if(!verif_play) return
      let _data = {
        robotMac: this.$store.state.currentLoginedRobot.robotMac,
        direction: direction,
        switchstate: 1
      };
      postNavigatePlay(_data).then((res) => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });
          // VideoStatus 1 奇臂 2 偶臂
          // 发送视频指令
          this.modePlayStatus = 3;
          this.$emit('changeVideoStatus',{
            videoStatus: direction,
            modePlayStatus:this.modePlayStatus
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/22 17:38:22
     * @Description 纯视频播放--结束视频
     */
    stopPosition() {
      // 需要强制结束
      // let verif_login = this.verificationlogin();
      // if(!verif_login) return
      // if(this.modePlayStatus != 3) {
      //   return
      // }

      // 机器人离线了也暂时不清空上一次在线机器人的信息
      let _data = {
        robotMac: this.$store.state.currentLoginedRobot.robotMac,
      };
      postNavigateStopPlay(_data).then((res) => {
        if (res.code=="000000") {
          this.$message({
            message: res.mesg,
            type: "success",
          });
          this.modePlayStatus = 0;
          // 发送视频指令
          this.$emit('changeVideoStatus',{
            videoStatus: 0,
            modePlayStatus:this.modePlayStatus
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error",
          });
        }
      });
    }
  },
};
</script>


<style  lang="scss">
.visua-mode-box {
  height: 100%;
  .pure-box {
    .mode-btn {
      text-align: center;
      padding: 0 !important;
      .el-button {
        margin-right: 0 !important;
        padding: 7px 9px !important;
      }
    }
  }
  .btn-disable {
    cursor: not-allowed;
  }
  .mode-box {
    // background-color: #1d5d92;
    opacity: .8;
    margin: 6px;
    padding: 8px;
    .mode-title {
      text-align: center;
      h3 {
        color: #ffffff;
        font-size: 0.8vw;
        margin: 0;
        padding: 1vh 0;
      }
    }
    .mode-form {
      .form-item {
        .el-form-item {
          display: flex;
          margin-bottom: 0px;
        }
        .el-form-item__label {
          font-size: 12px !important;
          color: #ffffff;
        }
        .el-form-item__content {
          width: 7vw;
          .el-input__inner {
            background-color: transparent;
            color: #ffffff;
          }
        }
      }
      .mode-btn {
        // padding: 2vh;
        padding-top: .0391rem;
        text-align: center;
        .el-button {
          &:first-of-type {
            margin-right: 1vw;
          }
        }
      }
    }
  }
}
</style>
